.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Bebas Neue',sans-serif;
  /* color: whitesmoke;
  background: rgb(6,0,36);
  background: linear-gradient(207deg, rgba(6,0,36,1) 9%, rgba(5,93,132,1) 41%, rgba(14,121,170,1) 70%); */

  /* background: rgb(255,255,255);
  background: linear-gradient(207deg, rgba(255,255,255,1) 15%, rgba(14,121,170,1) 62%, rgba(5,93,132,1) 88%);  */
}

.hiddenNav {
display: none;
}

h2.title {
  font-family: 'Play', sans-serif;
  font-size: 2rem;
}

.testimonial {
  background-color: #0C5B81	;
  border: solid #055a82 2px;
  color: #FFFFFF ;
  border-radius: 10px;
  width: 90%;
  max-height: 14rem;
}

.spacer {
  height: 20px;
}

.reactlink {
  text-decoration: none;
}

.resource {
  background-size: 100% !important;
  border: 2px solid black;
  border-radius: 10px;}

   /*SOCİAL İCONS*/
  
  /* footer social icons */
  
  ul.social-network {
    list-style: none;
    display: inline;
    margin-left: 0 !important;
    padding: 0;
  }
  
  ul.social-network li {
    display: inline;
    margin: 0 5px;
  }
  
  
  /* footer social icons */
  
  .social-network a.icoFacebook:hover {
    background-color: #3B5998;
  }
  
  .social-network a.icoInstagram:hover {
    background-color: #BE4BE1;
  }
  
  .social-network a.icoFacebook:hover i,
  .social-network a.icoInstagram:hover i {
    color: #fff;
  }
  
  .social-network a.socialIcon:hover,
  .socialHoverClass {
    color: #44BCDD;
  }
  
  .social-circle li a {
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    width: 3rem;
    height: 3rem;
    font-size: 2rem;
  }
  
  .social-circle li i {
    margin: 0;
    line-height: 30px;
    text-align: center;
  }
  
  .social-circle li a:hover i,
  .triggeredHover {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms--transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
  }
  
  .social-circle i {
    color: #595959;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
  }
  
  .social-network a {
    background-color: #F9F9F9;
  }
  
  p, ol {
    font-family: sans-serif;
  }

  .cal {
    width: 100%;
    height: 50vw;
  }

  .landing {
    height: 90vh
  }

  .jumbotron {
    margin-bottom: 0!important;
  }

  .btn-primary {
    background-color: #1878A9!important;
    border-color: #1878A9;
  }

  .home {
    color: white;
  }

  .bg-primary {
    background-color: #1878A9!important;
  }

  .nav-link {
    color: whitesmoke	!important
  }
  .nav-link.active {
    color: white!important;
    font-style: italic;
  }

 .container {
   padding-top: 2%!important;
 }

 .btn-secondary {
   background-color: #0C5B81;
   border-color: #0C5B81;
 }

 .resource {
  color:white;
  background-size:cover;
  background-repeat: no-repeat;
  background-color: black;
  background-position: center;
 }

 .footer {
  position: absolute;
  bottom: 0;
  width: 100%;
 }
